



























#bitPalette {
  width: 100%;
  min-height: 300px;

  canvas {
    outline: none;
  }
}
